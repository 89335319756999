import request from '@/utils/http'

export function createArticle(data) {
    return request({
        url: `/business/school/article`,
        method: 'post',
        data
    })
}

export function getArticleDetail(id) {
    return request({
        url: `/business/school/article/detail/${id}`,
        method: 'get'
    })
}

export function editArticle(data) {
    return request({
        url: `/business/school/article`,
        method: 'put',
        data
    })
}

// export function getComment(data) {
//     return request({
//         url: `/business/school/comment/search`,
//         method: 'post',
//         data
//     })
// }
export function getComment(data) {
    return request({
        url: `/business/school/comment?Article=${data.Article}`,
        method: 'get',
        data
    })
}

export function schoolLike(data){
    return request({
        url: `/business/school/comment/like`,
        method: 'post',
        data
    })
}

//上传图片前获取token
export function getImgToken() {
    return request({
        url: `/qiniu/image/token`,
        method: 'get'
    })
}

//上传视频前获取token
export function getVideoToken() {
    return request({
        url: `/qiniu/video/token`,
        method: 'get'
    })
}

export function schoolComment(data){
    return request({
        url:'/business/school/comment',
        method:"post",
        data
    })
}