<!--  -->
<template>
  <div class="home" ref="wrapper" :style="{ height: wrapperHeight + 'px' }" @click="onBlur()">
    <mt-loadmore
      :top-method="loadTop"
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
      :autoFill="isAutoFill"
    >
      <h3 class="padding">{{ data.Title }}</h3>
      <p class="time padding">{{ formatTime(data.Time) }}</p>
      <div id="quill_box"></div>
      <p class="employee padding">{{ data.Employee }}</p>
      <h4 class="padding">最新评论</h4>
      <div class="comment padding" v-for="(item, i) in this.discuss" :key="i">
        <comment :item="item" @onReply="onReply" :articleId="id" @onLike="onLike"/>
      </div>
    </mt-loadmore>
    <div style="height: 40px"></div>
    <div class="input">
      <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 4 }"
        :placeholder="setPlaceholder(replyName)"
        v-model="text"
        ref="mark"
      >
      </el-input>
      <div class="box">
        </div>
      <el-button type="danger" @click.stop="onComment">评论</el-button>
      
    </div>
  </div>
</template>

<script>
import { getArticleDetail, getComment,schoolComment } from "@/api/app";
import { timeFormat } from "@/utils/timeUtil";
import Quill from "quill";
import { setToken } from "@/utils/auth";
import comment from "./components/comment";
import { Message } from "element-ui";

export default {
  components: {
    comment,
  },
  data() {
    return {
      data: "",
      discuss: [],
      allLoaded: false,
      isAutoFill: false,
      wrapperHeight: 0,
      page: 1,
      text: "",
      id:"",
      parent:0,
      replyName:""
    };
  },
  computed: {
    formatTime() {
      return function (value) {
        return timeFormat(value);
      };
    },
    setPlaceholder(){
       return function (value) {
         if(value == ""){
          return "我来说两句"
         }else{
          return "回复 @" +value+":";
         }
      };
    }
  },
  watch: {},
  methods: {
    onBlur(){
      this.parent = 0;
      this.replyName = "";
    },
    onLike(){
      this.getcomment();
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    getcommentMore() {
      getComment({ Page: this.page + 1, Article: Number(this.id) }).then(
        (resp) => {
          console.log(resp);
          if (resp && resp.length > 0) {
            for (var i in resp) {
              this.discuss.push(resp[i]);
            }
            this.page = this.page + 1;
            this.$refs.loadmore.onBottomLoaded();
            this.allLoaded = false; // 可以进行上拉
          } else if (resp && resp.length == 0) {
            this.$refs.loadmore.onBottomLoaded();
          }
        }
      );
    },
    onReply(data){
      this.parent = data.Id;
      this.replyName = data.Employee
      console.log("parent----",this.parent);
      this.$refs['mark'].focus()
      console.log(1111)
      // this.getcomment()
    },
    getcomment() {
      this.page = 1;
      getComment({ Article: Number(this.id) }).then((resp) => {
        this.discuss = resp;
        this.allLoaded = false;
        this.$refs.loadmore.onTopLoaded();
                    this.$refs.loadmore.onBottomLoaded();
      });
    },
    loadTop() {
      this.getcomment();
    },
    loadBottom() {
         this.getcomment();
      // 加载更多数据
      // this.getcommentMore();
    },
    flutterCallJsMethod(message) {
      if (message == "评论") {
        this.getcomment();
        Message({
          message: `评论成功！`,
          type: "success",
        });
      }
    },
    onComment(){
      if(this.text != ""){
       var params = {"Text":this.text,"Article":Number(this.id),"Parent":this.parent};
      schoolComment(params).then(resp=>{
        console.log("resp",resp)
        this.text = ""
        this.parent = 0
        this.replyName = "";
          this.getcomment();
        Message({
          message: `评论成功！`,
          type: "success",
        });
      })
      }
    }
  },
  mounted() {
    //定义一个window对象由移动端调用
    window.flutterCallJsMethod = (message) => {
      this.flutterCallJsMethod(message);
    };
    // 父控件要加上高度，否则会出现上拉不动的情况
    this.wrapperHeight =
      document.documentElement.clientHeight -
      this.$refs.wrapper.getBoundingClientRect().top;

    this.quill = new Quill("#quill_box", {});
    this.quill.enable(false);
    this.id = this.getQueryVariable("Id");
    let token = this.getQueryVariable("Token");
    setToken(token);

    getArticleDetail(this.id).then((resp) => {
      this.data = resp;
      let json = JSON.parse(this.data.Content);
      for (var i = 0; i < json.length; i++) {
        if (json[i].insert && json[i].insert.image) {
          json[
            i
          ].insert.image = `${process.env.VUE_APP_CDNURL}${json[i].insert.image}`;
          console.log(json[i].image);
        }
      }
      for (let i = 0; i < json.length; i++) {
        if (json[i].insert && json[i].insert.video) {
          json[
            i
          ].insert.video = `${process.env.VUE_APP_CDNURL}${json[i].insert.video}`;
          console.log(json[i].video);
        }
      }
      console.log("------------JSON----", json);
      this.quill.setContents(json);
    });
    this.getcomment();
  },
};
</script>
<style lang='scss'>
.home {
  overflow: scroll;
  // padding-right: 15px;
  // padding-left: 15px;
  // padding-top: 15px;
}
.time {
  text-align: right;
}
.padding {
  padding-right: 15px;
  padding-left: 15px;
}
.input {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  width: 96vw;
  background-color: #f5f5f5;
  padding: 1vw 2vw;
  .box{
    width: 6px;
  }
}
.employee {
  text-align: right;
}
.ql-container {
  font-size: 15px;
  // line-height: 1.7;
}
.ql-editor .ql-video {
  display: block;
  object-fit: fill;
  width: 400px;
  height: 320px;
  margin: 10px 0;
}
h4 {
  text-align: left;
}
</style>