<!-- 评论 -->
<template>
  <div class="comment">
    <div class="row_between">
      <div class="avatar">
        <el-avatar size="large" :src="imgUrl + item.Avatar"></el-avatar>
        <div class="commentTop">
          <p>{{ item.Employee }}</p>
          <p>{{ formatTime(item.Time) }}</p>
        </div>
      </div>
      <div class="like" @click="onLike(item)">
        <el-badge :value="item.Like" class="item" :hidden="item.Like == 0">
        <img src="../../../assets/like2.png" alt="" srcset="" v-if="item.IsLike == 0" />
        <img src="../../../assets/like1.png" alt="" srcset="" v-if="item.IsLike == 1" />
        </el-badge>
      </div>
    </div>
    <div>
      <div v-if="more" class="packUp">{{ item.Text }}</div>
      <p v-if="!more" class="moreText">{{ item.Text }}</p>
      <div class="btn">
        <el-button type="text" class="more" @click.stop="onReply(item)"
          >回复</el-button
        >
        <el-button
          type="text"
          class="more"
          @click="onMore"
          v-if="item.Text && item.Text.length > 80"
          >{{ more ? "显示更多" : "收起" }}</el-button
        >
      </div>
    </div>
    <div v-if="item.Children && item.Children.length > 0" class="reply">
      <template v-if="!replyMore">
        <div class="reply_List" v-for="(item, i) in item.Children" :key="i">
          <div class="avatar">
            <el-avatar size="small" :src="imgUrl + item.Avatar"></el-avatar>
            <div class="commentTop">
              <p>{{ item.Employee }}</p>
              <p>{{ formatTime(item.Time) }}</p>
            </div>
          </div>
          <p>{{ item.Text }}</p>
        </div>
      </template>
      <div class="reply_footer" @click="onReplyMore">
        <div></div>
        <div>
          {{ replyMore ? `展开${item.Children.length}条回复` : "收起" }}
        </div>
        <i
          :class="replyMore ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { timeFormat } from "@/utils/timeUtil";
import {schoolLike} from "@/api/app"
export default {
  components: {},
  data() {
    return {
      more: true,
      replyMore: true,
      circleUrl: "",
      imgUrl: process.env.VUE_APP_CDNURL,
      isLike:false,
      
    };
  },
  props: {
    item: {},
    articleId:{
      type:String,
      default:""
    },
  },
  computed: {
    formatTime() {
      return function (value) {
        return timeFormat(value);
      };
    },
  },
  watch: {},
  methods: {
    onMore() {
      this.more = !this.more;
    },
    onReplyMore() {
      this.replyMore = !this.replyMore;
    },
    onReply(data) {
      this.$emit("onReply", data);
    },
    onLike(data){
      if(data.IsLike == 0){
      var params = {
        Comment:data.Id,
        Article:Number(this.articleId)
      };
      schoolLike(params).then((v)=>{
         this.$emit("onLike");
        console.log("=================>",v)
      })
      }
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.comment {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  .packUp {
    width: calc(100vw / 750 * 600);
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding: 0px 0px;
    margin: 0;
    margin-left: 44px;
    text-align: left;
  }
  .moreText {
    text-align: left;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 20;
    -webkit-box-orient: vertical;
    width: calc(100vw / 750 * 600);
    padding: 0px 0px;
    margin: 0;
    margin-left: 44px;
  }
  .reply {
    margin-left: 45px;
    font-size: 14px;
    .reply_footer {
      color: gray;
      display: flex;
      align-items: center;
      > div:nth-child(1) {
        height: 1;
        width: 20px;
        border-top: 1px solid gray;
        margin-right: 5px;
      }
    }
  }
  .row_between {
    width: 100%;
    display: flex;
    // justify-items: center;
    justify-content: space-between;
    align-items: center;

    .like {
      width: 30px;
      margin-right: 10px;
      float: right;
      img {
        width: 30px;
      }
    }
  }

  .reply_List {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    > p {
      text-align: left;
      font-size: 16px;
      padding: 0px 0px;
      margin: 0px;
      margin-left: 32px;
    }
  }
  .avatar {
    display: flex;
    align-items: center;
    // justify-content: center;
  }
  .btn {
    margin-left: 20px;
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
  }

  .more {
    text-align: center;
    color: orange;
    width: 80px;
  }
  .commentTop {
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    p:nth-child(1) {
      color: gray;
      margin: 0;
      text-align: left;
      padding: 0px 0px;
    }
    p:nth-child(2) {
      font-size: 12px;
      color: gray;
      text-align: left;
      margin: 0;
      padding: 0px 0px;
    }
  }
}
</style>